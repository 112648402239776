import classNames from "classnames";
import { FC } from "react";
import { Link, LinkProps } from "react-router-dom";
import { Button, ButtonProps, Spinner } from "reactstrap";
import { IconsId } from "../../../assets/fonts/iconfont/icons";
import { Icon } from "../Icon";

export type ButtonPropsT = ButtonProps & {
  title?: string;
  icon?: IconsId;
  kind?: "iconButton";
  onlyIconOnMobile?: boolean;
  onlyIcon?: boolean;
  className?: string;
  classNameForIcon?: string;
  wide?: boolean;
  noIconOnDesktop?: boolean;
  link?: LinkProps["to"];
  isLoading?: boolean;
};

export const StyledButton: FC<ButtonPropsT> = ({
  title,
  icon,
  kind,
  children,
  onlyIconOnMobile,
  onlyIcon,
  className,
  wide,
  noIconOnDesktop,
  link,
  isLoading = false,
  disabled,
  classNameForIcon,
  ...rest
}) => {
  return (
    <Button
      {...rest}
      disabled={isLoading || disabled}
      tag={link ? Link : undefined}
      to={link}
      className={classNames(
        {
          "icon-button": kind === "iconButton",
        },
        wide && "btn-wide",
        className
      )}
    >
      {icon && !isLoading && (
        <Icon
          className={classNames(
            [(title || children) && !onlyIconOnMobile && !onlyIcon && "me-2"],
            (title || children) && onlyIconOnMobile && "me-0 me-lg-2",
            (title || children) && noIconOnDesktop && "me-0 me-lg-2 d-lg-none",
            classNameForIcon
          )}
          icon={icon}
        />
      )}
      <span
        className={classNames(
          onlyIconOnMobile && "d-none d-lg-inline",
          onlyIcon && "d-none"
        )}
      >
        {title || children}
      </span>
      {isLoading && <Spinner className="mx-2" size="sm" />}
    </Button>
  );
};
