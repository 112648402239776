import { FC, ReactElement } from "react";
import { Navigate } from "react-router";
import { useSelector } from "../../store/hooks";
import { ROUTES } from "../../routes";

type Props = {
  children: ReactElement;
};

export const PrivateRoute: FC<Props> = ({ children }) => {
  const user = useSelector((state) => state.user.user);
  return user ? children : <Navigate to={ROUTES.login()} />;
};
