import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { Task } from "redux-saga";
import {
  all,
  cancel,
  call,
  delay,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { CONNECTION_CUTOFF } from "../../constants";
import { client } from "../../services/axios";
import { ApiErrorT, ApiSuccessModal } from "../../types/Common";
import {
  MowerConnectionT,
  MowerStatusT,
  MowerT,
  NearbyMowerT,
} from "../../types/Mower";
import { MowerData, MowerReplyData } from "../../types/Websocket";
import {
  setMowerLocationAction,
  setMowerInfoAction,
  StartMowerTrackingActionType,
  StartTrackingNearbyMowersActionType,
  setNearbyMowersAction,
  setLoadingAction,
  setMowerIsOnlineAction,
  AutoUploadRouteActionType,
  TrackedMowerT,
  setMowerStatusAction,
  setMowerRouteUploadingAction,
  CalibrateWheelsActionType,
  SubscribeToMowerConnectionActionType,
  unsubscribeFromMowerConnectionAction,
  setMowerConnectionDataAction,
  updateMowerStatusAction,
  UpdateMowerStatusActionType,
} from "../slices/mowerSlice";
import { responseWithModal } from "../../functions/responseWithModal";
import { RootState } from "..";
import {
  subscribeMessage,
  unsubscribeMessage,
} from "../../functions/websockets";
import { webSocket } from "./tasksSaga";
import {
  combineConnectionData,
  fetchMowerConnection,
  uploadRouteMutation,
} from "./functions";

// export function* updateMowerPositionSaga(mowerId: number) {
//   while (true) {
//     const { payload }: PayloadAction<MowerData> = yield take(
//       `mowerDataReceived_${mowerId}`
//     );

//     const { latitude, longitude, newPositions } = payload;

//     yield put(
//       setMowerLocationAction({
//         currentLocation: { lat: latitude, lng: longitude },
//         trajectory: newPositions,
//         mowerId,
//       })
//     );
//   }
// }

export function* updateImmediateMowerPositionSaga(mowerId: number) {
  while (true) {
    const { payload }: PayloadAction<MowerData> = yield take(
      `mowerCoordinateReceived_${mowerId}`
    );

    const { latitude, longitude, knivesOn } = payload;

    yield put(
      setMowerLocationAction({
        currentLocation: { lat: latitude, lng: longitude, knivesOn },
        trajectory: [{ lat: latitude, lng: longitude, knivesOn }],
        mowerId,
      })
    );
  }
}

function* updateNearbyMowersSaga(
  mowerId: number,
  deviceType: "ufon" | "mower"
) {
  // Subscribe to mower specific topic first
  webSocket.send(subscribeMessage(`${deviceType}.nearby.${mowerId}`));

  // Watch for nearbyMowers event and save its payload
  while (true) {
    const { payload }: PayloadAction<NearbyMowerT[]> = yield take(
      `nearbyMowers_${mowerId}`
    );

    yield put(setNearbyMowersAction({ nearbyMowers: payload, mowerId }));
  }
}

function* updateMowerStatusSaga({ payload }: UpdateMowerStatusActionType) {
  const { mowerId } = payload;
  const { data: mowerStatus }: AxiosResponse<MowerStatusT> = yield call(
    client.get,
    `/api/v1/mower/${mowerId}/status`
  );
  yield put(setMowerStatusAction({ mowerStatus, mowerId }));
}

function* startMowerTracking({ payload }: StartMowerTrackingActionType) {
  const { mowerId } = payload;

  yield put(setLoadingAction({ isLoading: true }));

  try {
    const mowerState: TrackedMowerT | undefined = yield select(
      (store: RootState) => store.mower.mowers[mowerId]
    );
    if (
      !mowerState ||
      !mowerState.currentLocation ||
      mowerState.isOnline === undefined
    ) {
      const { data }: AxiosResponse<MowerT> = yield call(
        client.get,
        `/api/v1/mower/${mowerId}`
      );

      yield put(
        setMowerInfoAction({
          mowerId,
          name: data.name,
          currentLocation: {
            lat: data.latitude,
            lng: data.longitude,
          },
        })
      );
      yield put(setMowerIsOnlineAction({ mowerId, isOnline: data.isOnline }));
    }
    if (!mowerState || !mowerState.mowerStatus) {
      const { data: mowerStatus }: AxiosResponse<MowerStatusT> = yield call(
        client.get,
        `/api/v1/mower/${mowerId}/status`
      );
      yield put(setMowerStatusAction({ mowerStatus, mowerId }));
    }

    yield put(setLoadingAction({ isLoading: false }));
    // const mowerTrackingTask: Task = yield fork(
    //   updateMowerPositionSaga,
    //   mowerId
    // );
    const mowerImmediateTrackingTask: Task = yield fork(
      updateImmediateMowerPositionSaga,
      mowerId
    );

    yield take("STOP_MOWER_TRACKING");
    // yield cancel(mowerTrackingTask);
    yield cancel(mowerImmediateTrackingTask);
  } catch (e) {
    const { message } = e as ApiErrorT;
    toast.error(message);
    yield put(setLoadingAction({ isLoading: false }));
  }
}

function* startTrackingNearbyMowers({
  payload,
}: StartTrackingNearbyMowersActionType) {
  const { initialNearbyMowers, deviceId, deviceType } = payload;
  yield put(
    setNearbyMowersAction({
      nearbyMowers: initialNearbyMowers,
      mowerId: deviceId,
    })
  );

  // Create task for updating nearby mowers in store
  const nearbyMowerTrackingTask: Task = yield fork(
    updateNearbyMowersSaga,
    deviceId,
    deviceType
  );

  // Wait for event and cancel tracking task
  yield take("STOP_NEARBY_MOWERS_TRACKING");
  yield cancel(nearbyMowerTrackingTask);

  // Unsubscribe from topic and clear store
  webSocket.send(unsubscribeMessage(`${deviceType}.nearby.${deviceId}`));
}

function* tryAutoUploadRoute({ payload }: AutoUploadRouteActionType) {
  const { jobId, mowerId, segmentId } = payload;

  const mowerState: TrackedMowerT | undefined = yield select(
    (state: RootState) => state.mower.mowers[mowerId]
  );

  yield put(setMowerRouteUploadingAction({ mowerId, routeUploading: true }));

  try {
    if (
      mowerState &&
      mowerState.isOnline &&
      !mowerState.routeUploaded &&
      mowerState.mowerStatus?.status.readyForUploadRoute
    ) {
      yield call(uploadRouteMutation, jobId, mowerId, segmentId);
    } else {
      const startTime = Date.now();
      const endTime = startTime + 120000;

      let mowerOnline = mowerState?.isOnline || false;
      let mowerReady =
        mowerState?.mowerStatus?.status.readyForUploadRoute || false;

      while (!mowerOnline || !mowerReady) {
        const {
          data,
          status,
          timeout,
        }: {
          data: PayloadAction<MowerT>;
          status: PayloadAction<MowerStatusT>;
          timeout: number;
        } = yield race({
          data: take(`mowerDataReceived_${mowerId}`),
          status: take(`mowerStatusReceived_${mowerId}`),
          timeout: delay(endTime - Date.now()),
        });

        if (timeout) {
          throw new Error(
            `Automatic upload of the route for mower ${mowerId} was not successful, try again manually`
          );
        }

        if (data && data.payload.isOnline) {
          mowerOnline = true;
        }
        if (status && status.payload.status.readyForUploadRoute) {
          mowerReady = true;
        }
      }

      if (mowerOnline && mowerReady) {
        yield call(uploadRouteMutation, jobId, mowerId, segmentId);
        const {
          mowerResponse,
        }: { mowerResponse: PayloadAction<MowerReplyData>; timeout: number } =
          yield race({
            mowerResponse: take(`mowerReplyReceived_${mowerId}_exportTrack`),
            timeout: delay(180000),
          });

        if (!mowerResponse || !mowerResponse.payload.success) {
          throw new Error(
            `Route was not accepted by mower ${mowerId}, try again manually`
          );
        }
      }
    }
  } catch (e) {
    const { message } = e as ApiErrorT;
    toast.error(message);
  } finally {
    yield put(setMowerRouteUploadingAction({ mowerId, routeUploading: false }));
  }
}

function* autoUploadRoute(action: AutoUploadRouteActionType) {
  yield race({
    upload: call(tryAutoUploadRoute, action),
    cancel: take("STOP_MOWER_TRACKING"),
  });
}

function* calibrateWheelSaga({ payload }: CalibrateWheelsActionType) {
  const { mowerId, confirm } = payload;

  try {
    const response: AxiosResponse<ApiSuccessModal> = yield call(
      client.put,
      `api/v1/mower/${mowerId}/calibrate-wheels`
    );
    responseWithModal({
      response,
      confirm,
      regularSuccess: () => {
        toast.success("Wheel calibration has started");
      },
      modalSuccess: async () => {
        toast.success("Wheel calibration has started");
      },
    });

    const { payload: reply }: PayloadAction<MowerReplyData> = yield take(
      `mowerReplyReceived_${mowerId}_calibWheels`
    );

    if (reply.success) {
      toast.success(reply.message);
    } else {
      toast.error(reply.message);
    }
  } catch (e) {
    const { message } = e as ApiErrorT;
    toast.error(message);
  }
}

function* subscribeToMowerConnectionSaga({
  payload,
}: SubscribeToMowerConnectionActionType) {
  const { mowerId } = payload;

  const dataCutoffLimit = CONNECTION_CUTOFF.mower;
  const connectionData: MowerConnectionT = yield call(
    fetchMowerConnection,
    mowerId
  );
  yield put(setMowerConnectionDataAction({ mowerId, connectionData }));

  webSocket.send(subscribeMessage(`mower.connection.${mowerId}`));

  while (true) {
    const {
      message,
      unsubscribe,
    }: {
      message: PayloadAction<MowerConnectionT>;
      unsubscribe: PayloadAction;
    } = yield race({
      message: take(`mowerDataConnectionReceived_${mowerId}`),
      unsubscribe: take(unsubscribeFromMowerConnectionAction.type),
    });

    if (message) {
      const currentConnection: MowerConnectionT = yield select(
        (state) => state.mower.mowers[mowerId].mowerConnection
      );
      yield put(
        setMowerConnectionDataAction({
          mowerId,
          connectionData: combineConnectionData(
            currentConnection,
            message.payload,
            dataCutoffLimit
          ),
        })
      );
    }

    if (unsubscribe) {
      webSocket.send(unsubscribeMessage(`mower.connection.${mowerId}`));
      break;
    }
  }
}

export function* rootMowerSaga() {
  yield all([
    takeEvery("SUBSCRIBE_TO_MOWER_CONNECTION", subscribeToMowerConnectionSaga),
    takeEvery("START_MOWER_TRACKING", startMowerTracking),
    takeEvery("TRACK_NEARBY_MOWERS", startTrackingNearbyMowers),
    takeEvery("TRY_AUTO_UPLOAD_ROUTE", autoUploadRoute),
    takeEvery("CALIBRATE_WHEELS", calibrateWheelSaga),
    takeLatest(updateMowerStatusAction.type, updateMowerStatusSaga),
  ]);
}
